// $primary-color:#00bfff;
// $warning-color:rgb(253, 11, 60);
// $secondary-color:#949191;
// $complimentary : #
// $primary-color:#dfdfdf;
$primary-color:#4c4c4c;
$warning-color:rgb(253, 11, 59);
// $warning-color:#fd0b3b;
$secondary-color: #FF9800;
$complimentary-color:rgb(255, 238, 0);
// $success-color:#23d878;
$success-color:#2ad062;
$text-color: #979797;